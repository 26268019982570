
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import MachineCard from "@/components/machine/machine_card.vue";
import MachineActivity from "@/components/machine/machine_activity.vue";
import MachineTimeline from "@/components/machine/machine_timeline.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "machine_show",
  props: {
    uuid: {
      type: String,
      default: null,
    },
  },
  components: {
    MachineCard,
    MachineActivity,
    MachineTimeline,
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageTitle("Machines List");
      
    });

    const store = useStore();
    const router = useRouter();

    store.commit(Mutations.SET_CURRENT_MACHINE, {});
    store.dispatch(Actions.GET_MACHINE, { id: props.uuid }).then((data) => {
      console.log("INSIDER!!!!!!")
      console.log(data)
      if (!data.id) {
        router.push({ name: "training-machines" });
      }
      if(data.type === 'default') {
          // push to training route if machine is training
          router.push({ name: "competition-machines-show", params: { uuid: data.id }});
      }
      console.log("INSIDER!!!!!!")
    });

    const machine = computed(() => {
      return store.getters.getCurrentMachine;
    });
    return {
      moment,
      machine,
      store
    };
  },
});
